new Swiper(".swiper-container", {
    slidesPerView: "auto",
    spaceBetween: 10,
    preventClicksPropagation: false,
    touchEventsTarget: "wrapper",
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
    }
});

/**
 * Pageloop makes your content loop and scroll forever.
 * It does not activate when content is shorter than viewport.
 * Deactivates for touch devices and firefox because the scroll position
 * cannot be changed while retaining momentum in those browsers.
 */
function Pageloop() {
    // Don't run in firefox
    if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        return;
    }

    this.loopContainer = document.body;
    this.duplicate = undefined;

    this.boundFuncs = {
        refresh: this.refresh.bind(this),
        scroll: this.handleScroll.bind(this),
        cancel: this.cancel.bind(this)
    };

    window.addEventListener(
        "load",
        function() {
            this.refresh();
            window.addEventListener("scroll", this.boundFuncs.scroll);
            window.addEventListener("resize", this.boundFuncs.refresh);
            window.addEventListener("touchstart", this.boundFuncs.cancel);
        }.bind(this)
    );
}

Pageloop.prototype.refresh = function() {
    this.deleteDuplicate();

    if (document.documentElement.offsetHeight >= window.innerHeight) {
        this.makeDuplicate();
    }
};

Pageloop.prototype.cancel = function() {
    this.deleteDuplicate();
    window.removeEventListener("resize", this.boundFuncs.refresh);
    window.removeEventListener("scroll", this.boundFuncs.scroll);
    window.removeEventListener("touchstart", this.boundFuncs.cancel);
};

Pageloop.prototype.handleScroll = function() {
    var currentScroll = window.pageYOffset || document.documentElement.scrollTop;

    if (currentScroll >= this.duplicate.offsetTop) {
        window.scrollTo(0, 0);
    }
};

Pageloop.prototype.makeDuplicate = function() {
    var wrap = document.createElement("div");
    wrap.style.position = "relative";
    for (var i = 0; i < this.loopContainer.children.length; i++) {
        var clone = this.loopContainer.children[i].cloneNode(true);
        wrap.appendChild(clone);
    }
    this.loopContainer.appendChild(wrap);
    this.duplicate = wrap;
};

Pageloop.prototype.deleteDuplicate = function() {
    if (this.duplicate) {
        // If there are any autoplaying videos, they will not be removed from memory.
        // This causes some browsers (ios safari) to crash.
        [].forEach.call(this.duplicate.getElementsByTagName("video"), function(vid) {
            vid.pause();
            vid.src = "";
            vid.load();
        });
        this.duplicate.parentNode.removeChild(this.duplicate);
        this.duplicate = undefined;
    }
};

Pageloop.prototype.getAbsoluteHeight = function(element) {
    var styles = window.getComputedStyle(element);
    var margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);
    return Math.ceil(element.scrollHeight + margin);
};

new Pageloop();
